<template>
  <router-view/>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
body{
  margin: 0;
  background-color: #04112d;
}
@font-face {
  font-family: 'PangMenZhengDao'; /* 定义字体族名称 */
  src: url('@/assets/fonts/pangmenzhengdao.ttf') format('truetype'); /* 指定字体文件的路径和格式 */
}
</style>
