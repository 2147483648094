<script>
import * as echarts from 'echarts';
import PanelCard from "@/views/dashboard-monitor/panel-card.vue";

import {listUserScale} from "@/api/userscale";

require('echarts/theme/macarons'); // echarts theme
export default {
  name: "UserScale",
  components: {PanelCard},
  data(){
    return {
      userScaleList: [],
      chart1 : null,
      chart2 : null,
      chart3 : null,
      scaleData : [],
      percent : [],
      color : ['#2AEEFD', '#12BEFE','#E5AF19'],
      colorBack : ['#12758c', '#09558a','#5e572c'],
      title : ['通算用户数', '智算用户数','超算用户数'],
    }
  },
  async created() {
    this.userScaleList = (await listUserScale()).rows;
    this.total = this.userScaleList?.map(scale=> scale.scale).reduce((previousValue, currentValue)=>{
      return previousValue + currentValue
    },0);
    this.scaleData.push(this.userScaleList?.find(scale=> scale.dataType == 1).scale);
    this.scaleData.push(this.userScaleList?.find(scale=> scale.dataType == 2).scale);
    this.scaleData.push(this.userScaleList?.find(scale=> scale.dataType == 3).scale);
    this.percent.push(Math.round(this.scaleData[0] / this.total * 100));
    this.percent.push(Math.round(this.scaleData[1] / this.total * 100));
    this.percent.push(Math.round(this.scaleData[2] / this.total * 100));
    this.$nextTick(() => {
      this.chart1 = echarts.init(this.$refs.chart1, 'macarons');
      this.chart2 = echarts.init(this.$refs.chart2, 'macarons');
      this.chart3 = echarts.init(this.$refs.chart3, 'macarons');
      this.initChart(this.chart1,0);
      this.initChart(this.chart2,1);
      this.initChart(this.chart3,2);
    });
  },
  methods: {
    formatNumberWithCommas(number) {
      if(number == null){
        return "0"
      }
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    initChart(chart,i) {
        const data = [
            { value: this.scaleData[i], itemStyle: {color: `${this.color[i]}`} },
            { value: this.total - this.scaleData[i], itemStyle: {color: `${this.colorBack[i]}`}, select: { disabled: true } },
            ]

      chart.setOption({
        series: [
          {
            name: 'user scale'+ i,
            type: 'pie',
            radius: [50, 60],
            center:["50%","50%"],
            emphasis: {
              disabled: true,
            },
            data,
            label: {
              formatter: [
                `{a|${this.formatNumberWithCommas(this.scaleData[i])}}`,
                `{b|${this.title[i]}}`
              ].join('\n'),

              rich: {
                a: {
                  color: `${this.color[i]}`,
                  lineHeight: 26,
                  fontSize:"18px",
                  fontFamily: "DIN Alternate",
                },
                b: {
                  opacity:0.6,
                  color: "#FFFFFF",
                  fontSize: "14px",
                  lineHeight: 22,
                  fontFamily:" Alibaba PuHuiTi",
                },
              },
              position: 'center'
            },
            labelLine: {
              show: false
            },
            animationEasing: 'cubicInOut',
            animationDuration: 2600,
          },
        ],
      });
    },
  },
}


</script>

<template>
  <panel-card  title="服务用户规模">
    <div class="chart-container">
      <div ref="chart1" class="chart"></div>
      <div ref="chart2" class="chart"></div>
      <div ref="chart3" class="chart"></div>
    </div>
<!--    <div class="data-container">-->
<!--      <div class="data" :style="{color:color[0]}">{{formatNumberWithCommas(scaleData[0])}}</div>-->
<!--      <div class="data" :style="{color:color[1]}">{{formatNumberWithCommas(scaleData[1])}}</div>-->
<!--      <div class="data" :style="{color:color[2]}">{{formatNumberWithCommas(scaleData[2])}}</div>-->
<!--    </div>-->
  </panel-card>
</template>

<style scoped lang="scss">
.chart-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 396px;
  height: 154px;
  margin: 30px 0 0 32px;
  .chart{
    position:relative;
    width:120px;
    height:120px;
  }
}
.data-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 396px;
  height: 32px;
  margin-left: 32px;
  .data{
    position:relative;
    width:120px;
    height:32px;
  }

}
</style>
